// SCROLL HEADER CLASS
$(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 20) {
        $("header").addClass("fixed");
        $("header").addClass("up");
    } else {
        if(!$('body').hasClass('home')){
            $('header').addClass('fixed');
        }else{
            $("header").removeClass("fixed");
        }
        $("header").removeClass("up");
    }

});


$(document).ready(function(){
    // TRIGGER MENU
    $('#trigger').click(function(){
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('header nav').slideUp();
        }else{
            $(this).addClass('open');
            $('header nav').slideDown();
        }
        $('header nav ul li a').click(function(){
            $('#trigger').removeClass('open');
            $('header nav').slideUp();
        });
    });


    //MENU FIXED WHEN NOT ON HOMEPAGE
    if(!$('body').hasClass('home')){
        $('header').addClass('fixed');
    }


    //FANCYBOX
    $(".singlenieuws ul.attachments li a.fancybox").fancybox();


    // HTML WIDTH
    function htmlresize(){
        var windowwidth = $(window).width();
        var windowheight = $(window).height();
        var wrapper = windowheight - 30 - 40;
        $('html').css({width: windowwidth});
        $('body .wrapper').css({minHeight: wrapper});
    }
    htmlresize();


    // HEADER HEIGHT
    function headerheight(){
        var windowheight = $(window).height() - 15;
        $('#home').css({minHeight: windowheight});
    }
    headerheight();


    // TRIANGLE HEIGHT WIDTH
    function headertriangle(){
        var headerWidth = $('#home').outerWidth();
        var headerHeight = $('#home').outerHeight();
        $("#home .triangle").css({"border-top": headerHeight + 'px solid rgba(161,211,201, 1)'});
        $("#home .triangle").css({"border-right": headerWidth + 'px solid transparent'});
    }
    headertriangle();


    // TEXTPAGE EQUAL HEIGHT
    function textpageequalheight(){
        $('section.textpage').each(function(){
            var titleheight = $(this).find('.title').height();
            var contentheight = $(this).find('.content').height();
            $(this).find('.title').css({minHeight: contentheight});
        });
        $('section.team').each(function(){
            var titleheight = $(this).find('.title').height();
            var contentheight = $(this).find('.content').height();
            $(this).find('.title').css({minHeight: contentheight});
        });
    }
    textpageequalheight();


    // TRIANGLE HEIGHT WIDTH
    function werkingtriangle(){
        var headerWidth = $('section.werking').outerWidth();
        var headerHeight = $('section.werking').outerHeight();
        $("section.werking .triangle").css({"border-top": headerHeight + 'px solid rgba(239,239,239, 1)'});
        $("section.werking .triangle").css({"border-right": headerWidth + 'px solid transparent'});
    }
    werkingtriangle();


    // TRIANGLE HEIGHT WIDTH
    function nieuwstriangle(){
        var headerWidth = $('section.nieuws').outerWidth();
        var headerHeight = $('section.nieuws').outerHeight();
        $("section.nieuws .triangle").css({"border-top": headerHeight + 'px solid rgba(239,239,239, 1)'});
        $("section.nieuws .triangle").css({"border-right": headerWidth + 'px solid transparent'});
    }
    nieuwstriangle();


    // TRIANGLE HEIGHT WIDTH
    function dicoinactietriangle(){
        var headerWidth = $('section.filmpjes').outerWidth();
        var headerHeight = $('section.filmpjes').outerHeight();
        $("section.filmpjes .triangle").css({"border-top": headerHeight + 'px solid rgba(107,191,178, 1)'});
        $("section.filmpjes .triangle").css({"border-right": headerWidth + 'px solid transparent'});
    }
    dicoinactietriangle();


    // TEAM IMAGE HEIGHT
    function teamimage(){
        $('section.team .teamgrid li').each(function(){
            var imagewidth = $(this).find('.image').width();
            $(this).find('.image').css({height: imagewidth});
        });
    }
    teamimage();


    // TRIANGLE HEIGHT WIDTH
    function contacttriangle(){
        var headerWidth = $('section.contact').outerWidth();
        var headerHeight = $('section.contact').outerHeight();
        $("section.contact .triangle").css({"border-top": headerHeight + 'px solid rgba(239,239,239, 1)'});
        $("section.contact .triangle").css({"border-right": headerWidth + 'px solid transparent'});
    }
    contacttriangle();


    $(window).resize(function(){
        htmlresize();
        headerheight();
        headertriangle();
        textpageequalheight();
        werkingtriangle();
        dicoinactietriangle();
        teamimage();
        contacttriangle();
        nieuwstriangle();
    });


    // REMOVE BR AFTER VIDEOS
    $('section.filmpjes .videos').find('br').remove();


    var getUrl = window.location;
    var sections = $('section')
      , nav = $('nav');
    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 105;
      sections.each(function() {
        var top = $(this).offset().top - 105,
            bottom = top + $(this).outerHeight() - 105;
        if (cur_pos >= top && cur_pos <= bottom) {
          nav.find('a').parent().removeClass('current');
          nav.find('a[href="http://'+getUrl.host+'/#'+$(this).attr('id')+'"]').parent().addClass('current');
        }
      });
    });


});


//SMOOTH SCROLL
$(document).ready(function(){
    $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 105
                }, 1000, function() {
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) {
                        return false;
                    } else {
                        $target.attr('tabindex','-1');
                        $target.focus();
                    };
                });
            }
        }
    });
    if (window.location.hash)
        scroll(0,0);
    setTimeout(function(){scroll(0,0);},1);
    $(function(){
    $('.scroll').on('click',function(e){
        e.preventDefault();
        $('html,body').animate({
            scrollTop:$($(this).attr('href')).offset().top - 105 + 'px'
        },1000,'swing');
    });
    if(window.location.hash){
        $('html,body').animate({
            scrollTop:$(window.location.hash).offset().top - 105 + 'px'
            },1000,'swing');
        }
    });
});
